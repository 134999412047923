<template>
  <div>
    <el-row>
      <el-col :span="18"
              style="overflow: auto;">

        <el-row>
          <div class="image">
            <div>
              <div class="label">答题卡正面
              </div>
              <div class="priview"
                   @click='turnPage("front")'
                   :class="{'is_active':isActive =='front'}"
                   :style="{
              width:fixed_1_width + 'px',
            height:fixed_1_height + 'px',
            background:`url(${form.excel_image_one})`,
            'background-size':`${fixed_1_width}px ${fixed_1_height}px`
        }">
                <div class="sign"
                     v-for="(item,index) in JSON.parse(form.mark_image_one_json)"
                     :key="index"
                     v-show="item.points.y"
                     :style="{
          top:item.points.y / ratioH+'px',
        left:item.points.x / ratioW+'px',
        width:item.points.w / ratioW+'px',
        height:item.points.h / ratioH+'px',
        background:item.color
        }">
                  {{item.label}}
                </div>

              </div>
            </div>

            <div>
              <div class="label">答题卡反面
              </div>
              <div class="priview"
                   :class="{'is_active':isActive =='reverse'}"
                   :style="{ 
            width:fixed_1_width + 'px',
            height:fixed_1_height + 'px',
            background:`url(${form.excel_image_two})`,
            'background-size':`${fixed_1_width}px ${fixed_1_height}px`
          }"
                   @click='turnPage("reverse")'>
                <div class="sign"
                     v-for="(item,index) in JSON.parse(form.mark_image_two_json)"
                     :key="index"
                     v-show="item.points.y"
                     :style="{
                top:item.points.y / ratioH+'px',
                left:item.points.x / ratioW+'px',
                width:item.points.w / ratioW+'px',
                height:item.points.h / ratioH+'px',
                background:item.color
        }">
                  {{item.label}}
                </div>
              </div>
            </div>
          </div>

        </el-row>
        <div class="topicImage">
          <div class="label">题目图片
          </div>
          <div class='imageWrap'>
            {{content}}
          </div>
          <pagination ref="pagination"
                      :get-data="initData"
                      :now-page.sync="page"
                      :now-size.sync="size"
                      :total="totalElements"
                      style='text-align:left' />
        </div>

        <el-row>

        </el-row>
      </el-col>
      <el-col :span="6">
        <div class='right'>
          <el-form ref="formRef"
                   label-width="80px"
                   style='overflow-y:auto;overflow-x:hidden;max-height:80vh;margin-bottom:20px;padding-right:10px'>
            <el-row :gutter="10"
                    v-for='(item,index) in topicItem'
                    :key='index'>
              <el-col :span="24">
                <el-form-item :label="item.label">
                  <el-input clearable
                            placeholder="请输入"
                            v-model="paperForm.paper_id"
                            @focus="topicContent(item.id)" />
                  <!--  -->
                </el-form-item>
              </el-col>
            </el-row>

            <!--  <el-row :gutter="10">
              <el-col :span="24">

                <el-form-item label="用户ID">
                  <el-input clearable
                            placeholder="请输入用户ID"
                            v-model="paperForm.paper_id" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="24">
                <el-form-item label="准考证ID">
                  <el-input clearable
                            placeholder="请输入准考证ID"
                            v-model="paperForm.paper_id" />
                </el-form-item>
              </el-col>

            </el-row> -->

          </el-form>
          <el-row :gutter="10"
                  style="text-align:right;padding-right:10px">
            <el-button type="primary"
                       @click="save">确定</el-button>
          </el-row>
        </div>
      </el-col>

    </el-row>
    <img :src="form.excel_image_one"
         class="fixed_1"
         alt="">
    <img :src="form.excel_image_two"
         class="fixed_2"
         alt="">
  </div>
</template>

<script>
import { uploadImageAuto, uploadExcelAuto } from '@/api/upload.js'
// import Sign from './components/index.vue'
import { saveSheet, continueMake, cancelApplyMake } from '@/api/sheet.js'
export default {
  data () {
    return {
      form: {
        // 教师试卷ID
        edu_paper_id: '',
        // 上传的excel 文件url地址
        excel_file: '',
        // 正面图片地址
        excel_image_one: '',
        // 反面图片地址
        excel_image_two: '',
        // 正面标注图片上传后地址
        mark_image_one: '',
        // 反面标注图片上传后地址
        mark_image_two: '',
        // 正面标注图片标注后json 数据
        mark_image_one_json: '[{"label":"A","points":{"x":0,"y":0,"w":0,"h":0}},{"label":"B","points":{"x":0,"y":0,"w":0,"h":0}},{"label":"C","points":{"x":0,"y":0,"w":0,"h":0}},{"label":"D","points":{"x":0,"y":0,"w":0,"h":0}}]',
        // 反面标注图片标注后json 数据
        mark_image_two_json: '[{"label":"A","points":{"x":0,"y":0,"w":0,"h":0}},{"label":"B","points":{"x":0,"y":0,"w":0,"h":0}},{"label":"C","points":{"x":0,"y":0,"w":0,"h":0}}]',
        // 教师试卷答题卡ID 修改必传
        // sheet_id: '',
        status: ''
      },
      show: false,
      // 正面预览图的属性
      // fixed_1_width: 560,
      // fixed_1_height: 417,
      fixed_1_width: 480,
      fixed_1_height: 353,

      // 当答题卡状态为以下内容时，才显示申请按钮
      // showApplyStatus: [0, -1, ''],

      isActive: "front",//选中正面/反面

      ratioH: 0,
      ratioW: 0,
      paperForm: {//题目表单数据
        paper_id: ""
      },
      topicItem: [],//测试，当前题目表单
      frontItem: [//测试，正面题目表单
        {
          label: "试卷ID",
          id: 0
        }, {
          label: "用户ID",
          id: 1
        },
        {
          label: "2",
          id: 2
        }
      ],
      reserveItem: [//测试，反面面题目表单
        {
          label: "试卷ID",
          id: 0
        }, {
          label: "用户ID",
          id: 1
        }, {
          label: '3',
          id: 3
        },
        {
          label: '4',
          id: 4
        }
      ],
      contentItem: [//测试，题目内容列表
        "A", "B", "C", "D", "E"
      ],
      content: "",//测试，当前题目内容

      page: 1,
      size: 10,
      totalElements: 0,
    }
  },
  components: {
    // Sign
  },
  mounted () {
    let sheet_id = this.$route.query.sheet_id
    if (Number(sheet_id)) {
      this.countinueMakeSheet(sheet_id)
    }

    let status = this.$route.query.status
    if (Number(status)) {
      this.form.status = status
    }

    this.topicItem = this.frontItem
  },
  methods: {

    getFixedImage () {
      setTimeout(() => {
        var img1 = document.getElementsByClassName('fixed_1')[0]
        this.ratioW = img1.clientWidth / 480
        this.ratioH = img1.clientHeight / 353
      }, 500);
    },

    async save () {
      //let form = JSON.parse(JSON.stringify(this.form))
      //form.edu_paper_id = this.$route.query.id
      // form.keep_id = 1
      // await saveSheet(form)
      // this.$message.success("操作成功")
      //this.$router.go(-1)
    },

    //获取答题卡标记区
    async countinueMakeSheet (id) {
      let params = {
        sheet_id: id
      }
      const { data } = await continueMake(params)
      this.form = data
      console.log(data)
      //console.log(JSON.parse(data.mark_image_one_json))
      this.$nextTick(() => {
        this.getFixedImage()
      })
    },


    //切换正反面
    turnPage (page) {
      this.isActive = page
      if (page == "front") {
        this.topicItem = this.frontItem
      } else {
        this.topicItem = this.reserveItem
      }
    },
    initData (page, limit) {
      console.log("this.initData")
    },
    //切换题目内容
    topicContent (index) {
      this.content = this.contentItem[index]
    }
  }
}
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 10px;
}
.btn_wrap {
  display: flex;

  .btn {
    padding: 0 10px;
    background: #409eff;
    border-radius: 4px;
    color: white;
    margin-right: 10px;
    height: 36px;
    line-height: 36px;
    cursor: pointer;
  }
}
.image {
  display: flex;
  .is_active {
    border: 1px solid #95c7f9;
    box-shadow: inset #7e9ab7 -1px 0px 9px;
  }
  .label {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    height: 34px;
    color: #333333;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    // div {
    //   width: 90px;
    //   height: 32px;
    //   background: #ecf5ff;
    //   border-radius: 2px;
    //   border: 1px solid #3f9eff;
    //   text-align: center;
    //   line-height: 32px;
    //   font-size: 14px;
    //   font-weight: bold;
    //   color: #3f9eff;
    //   margin-left: 20px;
    //   cursor: pointer;
    // }
  }
  div {
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
  }
  .btn {
    margin-left: 20px;
  }
}

.priview {
  position: relative;
  // width: 560px;
  // height: 417px;
  width: 480px;
  height: 353px;
  margin-left: 10px;
  border-radius: 10px;
  border: 2px solid #d9d9d9;
  margin-right: 50px;
  cursor: pointer;

  div {
    position: absolute;
    background: red;
    opacity: 0.3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.fixed_1 {
  position: fixed;
  right: 100000px;
}
.fixed_2 {
  position: fixed;
  right: 100000px;
}
::v-deep .el-message--warning {
  z-index: 2001 !important;
}
.add_img {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  border: 2px solid #d9d9d9;
  cursor: pointer;
  img {
    width: 80px;
    height: 72px;
    margin-bottom: 20px;
  }
}

.topicImage {
  margin: 20px 10px;
  margin-bottom: 0;
  .label {
    margin: 20px 0;
  }
  .imageWrap {
    width: 1190px;
    // height: 328px;
    height: 292px;
    border-radius: 13px;
    padding: 20px;
    border: 2px solid #d9d9d9;
  }
}
.right {
  padding: 40px 30px 20px 20px;

  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  /* 滚动条上的按钮 (上下箭头). */
  ::-webkit-scrollbar-button {
    display: none;
  }
  /* 滚动条上的滚动滑块. */
  ::-webkit-scrollbar-thumb {
    background-color: rgb(202, 202, 202);
    border-radius: 50px;
  }

  /* 滚动条没有滑块的轨道部分 */
  ::-webkit-scrollbar-track-piece {
    border-radius: 20px;
  }
  /* 当同时有垂直滚动条和水平滚动条时交汇的部分. */
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}
</style>