var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-row',[_c('el-col',{staticStyle:{"overflow":"auto"},attrs:{"span":18}},[_c('el-row',[_c('div',{staticClass:"image"},[_c('div',[_c('div',{staticClass:"label"},[_vm._v("答题卡正面 ")]),_c('div',{staticClass:"priview",class:{'is_active':_vm.isActive =='front'},style:({
            width:_vm.fixed_1_width + 'px',
          height:_vm.fixed_1_height + 'px',
          background:("url(" + (_vm.form.excel_image_one) + ")"),
          'background-size':(_vm.fixed_1_width + "px " + _vm.fixed_1_height + "px")
      }),on:{"click":function($event){return _vm.turnPage("front")}}},_vm._l((JSON.parse(_vm.form.mark_image_one_json)),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.points.y),expression:"item.points.y"}],key:index,staticClass:"sign",style:({
        top:item.points.y / _vm.ratioH+'px',
      left:item.points.x / _vm.ratioW+'px',
      width:item.points.w / _vm.ratioW+'px',
      height:item.points.h / _vm.ratioH+'px',
      background:item.color
      })},[_vm._v(" "+_vm._s(item.label)+" ")])}),0)]),_c('div',[_c('div',{staticClass:"label"},[_vm._v("答题卡反面 ")]),_c('div',{staticClass:"priview",class:{'is_active':_vm.isActive =='reverse'},style:({ 
          width:_vm.fixed_1_width + 'px',
          height:_vm.fixed_1_height + 'px',
          background:("url(" + (_vm.form.excel_image_two) + ")"),
          'background-size':(_vm.fixed_1_width + "px " + _vm.fixed_1_height + "px")
        }),on:{"click":function($event){return _vm.turnPage("reverse")}}},_vm._l((JSON.parse(_vm.form.mark_image_two_json)),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.points.y),expression:"item.points.y"}],key:index,staticClass:"sign",style:({
              top:item.points.y / _vm.ratioH+'px',
              left:item.points.x / _vm.ratioW+'px',
              width:item.points.w / _vm.ratioW+'px',
              height:item.points.h / _vm.ratioH+'px',
              background:item.color
      })},[_vm._v(" "+_vm._s(item.label)+" ")])}),0)])])]),_c('div',{staticClass:"topicImage"},[_c('div',{staticClass:"label"},[_vm._v("题目图片 ")]),_c('div',{staticClass:"imageWrap"},[_vm._v(" "+_vm._s(_vm.content)+" ")]),_c('pagination',{ref:"pagination",staticStyle:{"text-align":"left"},attrs:{"get-data":_vm.initData,"now-page":_vm.page,"now-size":_vm.size,"total":_vm.totalElements},on:{"update:nowPage":function($event){_vm.page=$event},"update:now-page":function($event){_vm.page=$event},"update:nowSize":function($event){_vm.size=$event},"update:now-size":function($event){_vm.size=$event}}})],1),_c('el-row')],1),_c('el-col',{attrs:{"span":6}},[_c('div',{staticClass:"right"},[_c('el-form',{ref:"formRef",staticStyle:{"overflow-y":"auto","overflow-x":"hidden","max-height":"80vh","margin-bottom":"20px","padding-right":"10px"},attrs:{"label-width":"80px"}},_vm._l((_vm.topicItem),function(item,index){return _c('el-row',{key:index,attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":item.label}},[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入"},on:{"focus":function($event){return _vm.topicContent(item.id)}},model:{value:(_vm.paperForm.paper_id),callback:function ($$v) {_vm.$set(_vm.paperForm, "paper_id", $$v)},expression:"paperForm.paper_id"}})],1)],1)],1)}),1),_c('el-row',{staticStyle:{"text-align":"right","padding-right":"10px"},attrs:{"gutter":10}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("确定")])],1)],1)])],1),_c('img',{staticClass:"fixed_1",attrs:{"src":_vm.form.excel_image_one,"alt":""}}),_c('img',{staticClass:"fixed_2",attrs:{"src":_vm.form.excel_image_two,"alt":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }